import { http } from "./setTokenInterceptor";
import fileDownload from "js-file-download";

export async function authUser(username, password) {
  const response = await http.post("/users/auth-token/", {
    username,
    password,
  });
  if (response.status === 200 && response.data.token) {
    return response.data.token;
  } else if (response.status === 400) {
    alert("Invalid credentials");
  } else {
    throw new Error(JSON.stringify(response.data));
  }
  return null;
}

export const getUsers = async () => {
  const response = await http.get("/users/user/");
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const logout = async () => {
  const response = await http.get("/auth/logout/");
  if (response.status === 202) {
    return true;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const expireCookies = async () => {
  const response = await http.get("/auth/expire/");
  return response.status === 200;
};

export const getUserGroups = async () => {
  // uses the access-token cookie to ID the current user
  const response = await http.get("/auth/user-groups/");
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getUserToClient = async () => {
  const response = await http.get("/users/user-to-client/");
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const createUserToClient = async (userId, clientId) => {
  const response = await http.post("/users/user-to-client/", {
    user: userId,
    client: clientId,
  });
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteUserToClient = async (userToClientId) => {
  const response = await http.delete(
    `/users/user-to-client/${userToClientId}/`
  );
  if (response.status === 204) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export async function findTarget(
  pageNumber,
  searchParams,
  indexName = undefined
) {
  const response = await http.post("/unified_profile/target_search/", {
    ...searchParams,
    page: pageNumber,
    index_name: indexName,
  });
  if (response.status === 200) {
    return response.data;
  } else {
    alert("Error, please reload the page");
    return null;
  }
}

export async function getDistrictOptions(state, indexName = undefined) {
  const response = await http.get("/unified_profile/district-options/", {
    params: { state, index_name: indexName },
  });
  if (response.status === 200) {
    return response.data;
  } else {
    alert("Error, please reload the page");
    return null;
  }
}

export async function searchCompanyName(
  companyName,
  page = 1,
  sortByBestMatch = true,
  indexName = undefined
) {
  const response = await http.get("/unified_profile/company_search/", {
    params: {
      company: companyName,
      page,
      sort_by_best_match: sortByBestMatch,
      index_name: indexName,
    },
  });
  if (response.status === 200) {
    return response.data;
  } else {
    alert("Error, please reload the page");
    return null;
  }
}

export async function getTitleSuggestions(
  companies,
  titles,
  page = 1,
  sortTitleByBestMatch = true,
  indexName = undefined
) {
  const response = await http.post("/unified_profile/top_titles_search/", {
    companies,
    titles,
    page,
    sort_by_best_match: sortTitleByBestMatch,
    index_name: indexName,
  });
  if (response.status === 200) {
    return response.data;
  } else {
    alert("Error, please reload the page");
    return null;
  }
}

export async function searchSchoolName(
  schoolName,
  page = 1,
  sortByBestMatch = true,
  indexName = undefined
) {
  const response = await http.get("/unified_profile/school_search/", {
    params: {
      school: schoolName,
      page,
      sort_by_best_match: sortByBestMatch,
      index_name: indexName,
    },
  });
  if (response.status === 200) {
    return response.data;
  } else {
    alert("Error, please reload the page");
    return null;
  }
}

export async function getMajorSuggestions(
  schools,
  majors,
  page = 1,
  sortByBestMatch = true,
  indexName = undefined
) {
  const response = await http.post("/unified_profile/top_majors_search/", {
    schools,
    majors,
    page,
    sort_by_best_match: sortByBestMatch,
    index_name: indexName,
  });
  if (response.status === 200) {
    return response.data;
  } else {
    alert("Error, please reload the page");
    return null;
  }
}

export async function getDCMProfile(dcmId, indexName = undefined) {
  const response = await http.get("/unified_profile/get-dcm-profile/", {
    params: { dcm_id: dcmId, index_name: indexName },
  });
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
}

export const getFirstDegreeEdges = async (
  targetId,
  projectId,
  limit = 10,
  page = 1
) => {
  const offset = (page - 1) * limit;
  const response = await http.get(
    `/audience-builder/api/project/${projectId}/audience-member/`,
    { params: { target_project_link: targetId, limit, offset } }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateTargetForAudienceMember = async (
  audienceMemberId,
  target,
  projectId
) => {
  const response = await http.patch(
    `/audience-builder/api/project/${projectId}/audience-member/${audienceMemberId}/`,
    { target_project_link_id: target }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteEdges = async (projectId, edgeIds) => {
  const response = await http.post(
    `/audience-builder/api/project/${projectId}/audience-member/bulk-delete/`,
    { ids: edgeIds }
  );
  if (response.status === 204) {
    return true;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getTargets = async (projectId, limit = 10, page = 1) => {
  const offset = (page - 1) * limit;
  const response = await http.get(
    `/audience-builder/api/project/${projectId}/target-project-link/`,
    { params: { limit, offset } }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteTpl = async (tplId, projectId) => {
  const response = await http.delete(
    `/audience-builder/api/project/${projectId}/target-project-link/${tplId}/`
  );
  if (response.status !== 204) {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export async function getProject(projectId) {
  const response = await http.get(
    `/audience-builder/api/project/${projectId}/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
}

export async function updateProject(projectId, params) {
  const response = await http.patch(
    `/audience-builder/api/project/${projectId}/`,
    params
  );
  if (response.status === 200) {
    return true;
  } else {
    return response.data;
  }
}

export async function updateAudienceSegment(segmentId, projectId, values) {
  const response = await http.patch(
    `/audience-builder/api/project/${projectId}/audience-segment/${segmentId}/`,
    values
  );
  if (response.status === 200) {
    return true;
  } else if (response.status === 400) {
    return response.data;
  }
  throw new Error(JSON.stringify(response.data));
}

export async function createAudienceSegment(projectId, values) {
  const response = await http.post(
    `/audience-builder/api/project/${projectId}/audience-segment/`,
    values
  );
  if (response.status === 201) {
    return [];
  } else if (response.status === 400) {
    return response.data;
  }
  throw new Error(JSON.stringify(response.data));
}

export async function deleteAudienceSegment(
  segmentId,
  projectId,
  source,
  segment_type
) {
  const response = await http.delete(
    `/audience-builder/api/project/${projectId}/audience-segment/${segmentId}/`,
    {
      params: {
        segment_id: segmentId,
        project_id: projectId,
        source,
        segment_type,
      },
    }
  );
  if (response.status === 204) {
    return true;
  }
  console.error(response.data);
  throw new Error(JSON.stringify(response.data));
}

export async function deleteAudienceMembersBySegment(
  projectId,
  segmentId,
  targetId,
  source,
  segment_type
) {
  const params = {
    segment_id: segmentId,
    target_id: targetId,
    source,
    segment_type,
  };
  const response = await http.post(
    `/audience-builder/api/project/${projectId}/audience-member/delete-by-segment/`,
    params
  );
  if (response.status === 204) {
    return true;
  }
  console.error(response.data);
  throw new Error(JSON.stringify(response.data));
}

export const getAudienceSegment = async (segmentId, projectId) => {
  const response = await http.get(
    `/audience-builder/api/project/${projectId}/audience-segment/${segmentId}/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getAudienceSegments = async (projectId, targetProjectLinkId) => {
  const params = { audience_members__target_project_link: targetProjectLinkId };
  const response = await http.get(
    `/audience-builder/api/project/${projectId}/audience-segment/`,
    { params }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDecisionMakers = async (dmIds) => {
  const response = await http.post(`dms/get-decision-makers/`, {
    ids: dmIds,
  });

  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDecisionMaker = async (dmId) => {
  const response = await http.get(`/dms/decision-maker/${dmId}/`);
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const findDecisionMaker = async (firstName, lastName) => {
  const response = await http.post(`dms/find-decision-makers/`, {
    first_name: firstName || null,
    last_name: lastName || null,
  });
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createCoworkerAudienceMembers = async (
  dm_id,
  desired_size,
  projectId,
  targetId,
  selectedStates,
  is_dc_metro
) => {
  const response = await http.post(
    `/audience-builder/api/eas/create-coworker-audience-members/`,
    {
      dm_id: dm_id,
      desired_size: desired_size,
      project_id: projectId,
      target: targetId,
      states: selectedStates,
      is_dc_metro: is_dc_metro,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createAssociationsAudienceMembers = async (
  dm_id,
  desired_size,
  projectId,
  targetId,
  selectedStates,
  is_dc_metro
) => {
  const response = await http.post(
    `/audience-builder/api/eas/create-association-audience-members/`,
    {
      dm_id: dm_id,
      desired_size: desired_size,
      project_id: projectId,
      target: targetId,
      states: selectedStates,
      is_dc_metro: is_dc_metro,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const findNeighbors = async (p_id, desired_size = 5) => {
  const response = await http.post("mps/get-neighbors-v2/", [
    {
      p_id,
      desired_size,
    },
  ]);
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createNeighborsAudienceMembers = async (
  dm_id,
  desired_size,
  projectId,
  targetId
) => {
  const response = await http.post(
    `/audience-builder/api/neighbor/create-neighbor-segment/`,
    {
      dm_id: dm_id,
      desired_size: desired_size,
      project_id: projectId,
      target: targetId,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const createLocationBasedAudienceMembers = async (
  dm_id,
  projectId,
  targetId,
  selectedStates,
  selectedCongressionalDistricts,
  locationBasedSegmentsRequest
) => {
  const response = await http.post(
    `/audience-builder/api/location-based/create-location-based-segments/`,
    {
      dm_id: dm_id,
      project_id: projectId,
      target_id: targetId,
      selected_states: selectedStates,
      selected_cong_districts: selectedCongressionalDistricts,
      segment_types: locationBasedSegmentsRequest,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createLocalActivistsAudienceMembers = async (
  activistAPICallProjectInfo,
  selectedStates,
  activistSegmentsRequest,
  selectedCongressionalDistricts,
  isDCMetroArea
) => {
  const response = await http.post(
    `/audience-builder/api/donor/create-local-activists/`,
    {
      dm_id: activistAPICallProjectInfo["dm_id"],
      project_id: activistAPICallProjectInfo["project_id"],
      target_id: activistAPICallProjectInfo["target_id"],
      selected_states: selectedStates,
      segment_types: activistSegmentsRequest,
      selected_cong_districts: selectedCongressionalDistricts,
      is_dc_metro_area: isDCMetroArea,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createClassmateAudienceMembers = async (
  dm_id,
  desired_size,
  projectId,
  targetId,
  selectedStates,
  is_dc_metro
) => {
  const response = await http.post(
    `/audience-builder/api/eas/create-classmate-audience-members/`,
    {
      dm_id: dm_id,
      desired_size: desired_size,
      project_id: projectId,
      target: targetId,
      states: selectedStates,
      is_dc_metro: is_dc_metro,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createDecisionMaker = async (
  firstName,
  lastName,
  nickname,
  title,
  type,
  electedOfficeStatus
) => {
  const response = await http.post(`dms/decision-maker/`, {
    first_name: firstName,
    last_name: lastName,
    nickname: nickname,
    dm_title: title,
    decision_maker_type: type,
    elected_office_status: electedOfficeStatus,
  });
  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(response.data.detail);
  }
};

export const deleteDecisionMaker = async (dmId) => {
  console.log(dmId);
  const response = await http.delete(
    `/audience-builder/api/decision-maker/delete-decision-maker/`,
    {
      data: {
        decision_maker_id: dmId,
      },
    }
  );
  if (response.status === 200) {
    return response.status;
  } else {
    console.error(response);
    alert(response.data.detail);
    return response.status;
  }
};

export const addPidToDecisionMaker = async (dmId, p_id) => {
  const response = await http.patch(
    `/audience-builder/api/decision-maker/${dmId}/add-pid-to-dm/`,
    {
      p_id,
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateDecisionMaker = async (
  dmId,
  {
    first_name,
    nickname,
    last_name,
    dob_year,
    dob_day,
    dob_month,
    decision_maker_type,
    political_party,
    represented_city,
    represented_state,
    congressional_district,
    dcm_id,
    eas_profile_id,
    p_id,
    represented_state_upper_district,
    represented_state_lower_district,
    represented_county,
    elected_office_status,
    dm_title,
    is_researched,
  }
) => {
  const response = await http.put(`/dms/decision-maker/${dmId}/`, {
    first_name,
    nickname,
    last_name,
    dob_year,
    dob_day,
    dob_month,
    decision_maker_type,
    political_party,
    represented_city,
    represented_state,
    congressional_district,
    dcm_id,
    eas_profile_id,
    p_id,
    represented_state_upper_district,
    represented_state_lower_district,
    represented_county,
    elected_office_status,
    dm_title,
    is_researched,
  });
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDecisionMakerAddresses = async (decisionMakerId) => {
  const response = await http.get(
    `/dms/decision-maker/${decisionMakerId}/address/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const findEASCompanies = async (name) => {
  const response = await http.get(
    `/audience-builder/api/eas/find-eas-companies/`,
    {
      params: {
        name,
      },
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getEASCompanies = async (company_ids) => {
  const response = await http.post(`/eas/get-companies/`, {
    company_ids: company_ids,
  });
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getEASInstitutes = async (institution_ids) => {
  const response = await http.post(`/eas/get-institutions/`, {
    institution_ids: institution_ids,
  });
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const findEASInstitution = async (name) => {
  const response = await http.get(
    `/audience-builder/api/eas/find-eas-institutions/`,
    {
      params: {
        name: name,
      },
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDecisionMakerExperiences = async (decisionMakerId) => {
  const response = await http.get(
    `/dms/decision-maker/${decisionMakerId}/experience/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createDecisionMakerExperience = async (
  decisionMakerId,
  {
    companyId,
    companyName,
    fromDate,
    toDate,
    city,
    state,
    isAssociation,
    isPrincipal,
  }
) => {
  const response = await http.post(
    `/dms/decision-maker/${decisionMakerId}/experience/`,
    {
      decision_maker_id: decisionMakerId,
      company_id: companyId,
      company_name: companyName,
      from_date: fromDate,
      to_date: toDate,
      city,
      state,
      is_association: isAssociation,
      is_principal: isPrincipal,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateDecisionMakerExperience = async (
  decisionMakerId,
  experienceId,
  {
    companyId,
    companyName,
    fromDate,
    toDate,
    city,
    state,
    isAssociation,
    isPrincipal,
  }
) => {
  const response = await http.put(
    `/dms/decision-maker/${decisionMakerId}/experience/${experienceId}/`,
    {
      decision_maker_id: decisionMakerId,
      company_id: companyId,
      company_name: companyName,
      from_date: fromDate,
      to_date: toDate,
      city,
      state,
      is_association: isAssociation,
      is_principal: isPrincipal,
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteDecisionMakerExperience = async (
  decisionMakerId,
  experienceId
) => {
  const response = await http.delete(
    `/dms/decision-maker/${decisionMakerId}/experience/${experienceId}/`
  );
  if (response.status === 204) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDecisionMakerEducations = async (decisionMakerId) => {
  const response = await http.get(
    `/dms/decision-maker/${decisionMakerId}/education/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createDecisionMakerEducation = async (
  decisionMakerId,
  { institutionId, institutionName, fromDate, toDate }
) => {
  const response = await http.post(
    `/dms/decision-maker/${decisionMakerId}/education/`,
    {
      decision_maker_id: decisionMakerId,
      institution_id: institutionId,
      institution_name: institutionName,
      from_date: fromDate,
      to_date: toDate,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateDecisionMakerEducation = async (
  decisionMakerId,
  educationId,
  { institutionId, institutionName, fromDate, toDate }
) => {
  const response = await http.put(
    `/dms/decision-maker/${decisionMakerId}/education/${educationId}/`,
    {
      decision_maker_id: decisionMakerId,
      institution_id: institutionId,
      institution_name: institutionName,
      from_date: fromDate,
      to_date: toDate,
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteDecisionMakerEducation = async (
  decisionMakerId,
  educationId
) => {
  const response = await http.delete(
    `/dms/decision-maker/${decisionMakerId}/education/${educationId}/`
  );
  if (response.status === 204) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createDecisionMakerAddress = async (
  decisionMakerId,
  { streetAddress, city, state, zipcode, addr2 }
) => {
  const response = await http.post(
    `/dms/decision-maker/${decisionMakerId}/address/`,
    {
      street_address: streetAddress,
      city,
      state,
      zipcode,
      addr2,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateDecisionMakerAddress = async (
  decisionMakerId,
  addressId,
  { streetAddress, city, state, zipcode, addr2 }
) => {
  const response = await http.put(
    `/dms/decision-maker/${decisionMakerId}/address/${addressId}/`,
    {
      street_address: streetAddress,
      city,
      state,
      zipcode,
      addr2,
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteDecisionMakerAddress = async (
  decisionMakerId,
  addressId
) => {
  const response = await http.delete(
    `/dms/decision-maker/${decisionMakerId}/address/${addressId}/`
  );
  if (response.status === 204) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDecisionMakerEmails = async (decisionMakerId) => {
  const response = await http.get(
    `/dms/decision-maker/${decisionMakerId}/email/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createDecisionMakerEmail = async (decisionMakerId, email) => {
  const response = await http.post(
    `/dms/decision-maker/${decisionMakerId}/email/`,
    {
      decision_maker_id: decisionMakerId,
      email_id: email,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateDecisionMakerEmail = async (
  decisionMakerId,
  emailId,
  email
) => {
  const response = await http.put(
    `/dms/decision-maker/${decisionMakerId}/email/${emailId}/`,
    {
      decision_maker_id: decisionMakerId,
      email_id: email,
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteDecisionMakerEmail = async (decisionMakerId, emailId) => {
  const response = await http.delete(
    `/dms/decision-maker/${decisionMakerId}/email/${emailId}/`
  );
  if (response.status === 204) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDecisionMakerPhoneNumbers = async (decisionMakerId) => {
  const response = await http.get(
    `/dms/decision-maker/${decisionMakerId}/phone/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createDecisionMakerPhoneNumber = async (
  decisionMakerId,
  { phoneNumber, phoneType }
) => {
  const response = await http.post(
    `/dms/decision-maker/${decisionMakerId}/phone/`,
    {
      decision_maker_id: decisionMakerId,
      phone_number: phoneNumber,
      phone_type: phoneType,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateDecisionMakerPhoneNumber = async (
  decisionMakerId,
  phoneId,
  { phoneNumber, phoneType }
) => {
  const response = await http.put(
    `/dms/decision-maker/${decisionMakerId}/phone/${phoneId}/`,
    {
      decision_maker_id: decisionMakerId,
      phone_number: phoneNumber,
      phone_type: phoneType,
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteDecisionMakerPhoneNumber = async (
  decisionMakerId,
  phoneId
) => {
  const response = await http.delete(
    `/dms/decision-maker/${decisionMakerId}/phone/${phoneId}/`
  );
  if (response.status === 204) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const findExistingPackages = async (packageName) => {
  const response = await http.get(
    `/audience-builder/api/package/find-existing-packages/`,
    { params: { name: packageName } }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getUniqueTarget = async (targetId) => {
  const response = await http.get(`/audience-builder/api/target/${targetId}/`);
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getTarget = async (targetId, projectId) => {
  const response = await http.get(
    `/audience-builder/api/project/${projectId}/target-project-link/${targetId}/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDecisionMakerTaxonomy = async () => {
  const response = await http.get(`/dms/decision-maker-taxonomy/`);
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getPoliticalParties = async () => {
  const response = await http.get(
    `/audience-builder/api/constants/political-parties/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getPhoneTypes = async () => {
  const response = await http.get(
    `/audience-builder/api/constants/phone-types/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const addTargets = async (projectId, dm_profiles) => {
  const response = await http.post(
    `/audience-builder/api/project/${projectId}/target-project-link/bulk-create/`,
    {
      project: projectId,
      dm_profiles: dm_profiles,
    }
  );

  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateTargets = async (projectId, new_values) => {
  const response = await http.patch(
    `/audience-builder/api/project/${projectId}/target-project-link/bulk-update/`,
    { ...new_values }
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const addTarget = async ({
  project,
  internal_key,
  first_name,
  middle_name,
  last_name,
  dob_year,
  dob_month,
  dob_day,
  street_address,
  city,
  state,
  congressional_district,
  zipcode,
  target,
  dcm_ids,
  is_package,
  type,
  political_party,
}) => {
  const response = await http.post(
    `/audience-builder/api/project/${project}/target-project-link/`,
    {
      project,
      internal_key,
      first_name,
      middle_name,
      last_name,
      dob_year,
      dob_month,
      dob_day,
      street_address,
      city,
      state,
      congressional_district,
      zipcode,
      target,
      dcm_ids,
      is_package,
      type,
      political_party,
      dm_id: null,
    }
  );

  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const addPackage = async ({ project, package_id }) => {
  const response = await http.post(
    `/audience-builder/api/project/${project}/target-project-link/`,
    {
      project,
      package: package_id,
    }
  );

  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateTarget = async ({
  targetId,
  projectId,
  internalKey,
  firstName,
  middleName,
  lastName,
  streetAddress,
  city,
  state,
  congressional_district,
  zipcode,
  birthYear,
  birthMonth,
  birthDay,
  is_package,
  type,
  political_party,
  dm_network_size,
}) => {
  // Note: PATCH instead of PUT because we're not sending DCM Ids
  const response = await http.patch(
    `/audience-builder/api/project/${projectId}/target-project-link/${targetId}/`,
    {
      internal_key: internalKey,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      dob_year: birthYear,
      dob_month: birthMonth,
      dob_day: birthDay,
      street_address: streetAddress,
      city,
      state,
      congressional_district,
      zipcode,
      is_package,
      type,
      political_party,
      dm_network_size,
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateTargetDCMIds = async (targetId, projectId, dcmIds) => {
  const response = await http.patch(
    `/audience-builder/api/project/${projectId}/target-project-link/${targetId}/`,
    { dcm_ids: dcmIds }
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateTargetExpectedDeactivation = async (
  targetId,
  projectId,
  expectedDeactivationDate
) => {
  const response = await http.patch(
    `/audience-builder/api/project/${projectId}/target-project-link/${targetId}/`,
    { expected_deactivation_date: expectedDeactivationDate }
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getProjects = async () => {
  const response = await http.get("/audience-builder/api/project/", {});

  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getProjectFilterOptions = async () => {
  const response = await http.get(
    "/audience-builder/api/project/filter-options/"
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const addProject = async (params) => {
  const response = await http.post(`/audience-builder/api/project/`, params);

  if (response.status === 201) {
    return [response.data.id, {}];
  } else {
    return [null, response.data];
  }
};

export const getClients = async () => {
  const response = await http.get("/audience-builder/api/client/");
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getMyClients = async () => {
  const response = await http.get("/audience-builder/api/client/my-clients/");
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getClientProjects = async (clientId) => {
  const response = await http.get("/audience-builder/api/client-project/", {
    params: { client_id: clientId },
  });
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getSegmentNames = async (projectId = undefined) => {
  const response = await http.get("/audience-builder/api/segment-type/", {
    params: { audience_segments__project: projectId },
  });
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

const exportAudience = async (projectId, platformName) => {
  const response = await http.get(
    `/audience-builder/api/project/${projectId}/export/`,
    { params: { platform: platformName } }
  );
  if (response.status === 200) {
    return fileDownload(
      response.data,
      `${platformName}_audience.csv`,
      "text/csv"
    );
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const exportFacebookAudience = async (projectId) => {
  return await exportAudience(projectId, "facebook");
};

export const exportLiverampAudience = async (projectId) => {
  return await exportAudience(projectId, "liveramp");
};

export const getIndustries = async (projectId) => {
  const response = await http.get(
    `/audience-builder/api/project/${projectId}/get-industries/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getAudienceMemberLogs = async (
  projectId,
  target_project_link = undefined
) => {
  const response = await http.get(
    `/audience-builder/api/project/${projectId}/audience-creation-log/`,
    { params: { target_project_link } }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteAudienceMemberLog = async (projectId, logId) => {
  const response = await http.delete(
    `/audience-builder/api/project/${projectId}/audience-creation-log/${logId}/`
  );
  if (response.status !== 204) {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getAudienceBreakdown = async (projectId) => {
  const response = await http.get(
    `/audience-builder/api/project/${projectId}/display-audience-breakdown/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const createAudienceExportV3 = async (
  projectId,
  export_details,
  onboarding
) => {
  const response = await http.post(
    `/audience-builder/api/project/${projectId}/export-to-s3-v3/`,
    {
      export_details: export_details,
      onboarding: onboarding,
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getAudienceMemberFilters = async (
  projectId,
  target_project_link,
  audience_segment
) => {
  const response = await http.get(
    `/audience-builder/api/project/${projectId}/audience-member-filter/`,
    { params: { target_project_link, audience_segment } }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getTargetDspFiles = async (projectId) => {
  const response = await http.get(
    `/audience-builder/api/project/${projectId}/dsp-export-file/target-files/`,
    {}
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createAudienceMemberFilter = async (
  projectId,
  targetProjectLink,
  audienceSegment,
  filterParams,
  firstDegree
) => {
  const response = await http.post(
    `/audience-builder/api/project/${projectId}/audience-member-filter/`,
    {
      filter_params: filterParams,
      first_degree: firstDegree,
      project: projectId,
      target_project_link: targetProjectLink,
      audience_segment: audienceSegment,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateAudienceMemberFilter = async (
  filterId,
  projectId,
  targetProjectLink,
  audienceSegment,
  filterParams,
  firstDegree
) => {
  const response = await http.patch(
    `/audience-builder/api/project/${projectId}/audience-member-filter/${filterId}/`,
    {
      filter_params: filterParams,
      first_degree: firstDegree,
      project: projectId,
      target_project_link: targetProjectLink,
      audience_segment: audienceSegment,
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteAudienceMemberFilter = async (projectId, filterId) => {
  const response = await http.delete(
    `/audience-builder/api/project/${projectId}/audience-member-filter/${filterId}/`
  );
  if (response.status !== 204) {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDesiredAudienceSizes = async (projectId) => {
  const response = await http.get(
    `/audience-builder/api/project/${projectId}/desired-audience-size/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateOrCreateDesiredAudienceSizes = async (
  projectId,
  targetId,
  segmentSizes
) => {
  const response = await http.post(
    `/audience-builder/api/project/${projectId}/desired-audience-size/update-or-create/`,
    {
      project: projectId,
      target_project_link: targetId,
      segment_sizes: segmentSizes,
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getS3DataUploadLogs = async (projectId) => {
  const response = await http.get(
    `/audience-builder/api/project/${projectId}/s3-data-upload-log/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const findPerson = async (searchParams) => {
  const response = await http.post(`mps/find-person/`, searchParams);
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const findPersonV2 = async (searchParams) => {
  const response = await http.post(`mps/find-person-v2/`, searchParams);
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getPii = async (p_ids) => {
  const response = await http.post(`mps/get-pii/`, { p_ids });
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const attachHVCToDecisionMaker = async (hvc, dm_id) => {
  const response = await http.post(
    `/dms/decision-maker/${dm_id}/high-value-connection/`,
    hvc
  );
  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getHVCForDecisionMaker = async (dm_id) => {
  const response = await http.get(
    `/dms/decision-maker/${dm_id}/high-value-connection/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteHVC = async (dm_id, id) => {
  const response = await http.delete(
    `/dms/decision-maker/${dm_id}/high-value-connection/${id}/`
  );
  if (response.status === 204) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateHVCType = async (dm_id, id, params) => {
  const response = await http.put(
    `/dms/decision-maker/${dm_id}/high-value-connection/${id}/`,
    params
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const saveHVCConfiguration = async (project_id, req_data) => {
  const response = await http.patch(
    `/audience-builder/api/project/${project_id}/`,
    req_data
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getExistingDonationUploads = async (decisionMakerId) => {
  const response = await http.get(
    `/audience-builder/api/decision-maker/get-donation-uploads/`,
    { params: { dm_id: decisionMakerId } }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const uploadDonationsData = async (formData) => {
  const response = await http.post(
    `/audience-builder/api/decision-maker/upload-donation-data/`,
    formData
  );

  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const uploadDonationsDataV2 = async (formData) => {
  const response = await http.post(
    `/audience-builder/api/donor/upload-donation-data-v2/`,
    formData
  );

  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteDonationsData = async (
  decisionMakerId,
  candidateId,
  uploadId
) => {
  const response = await http.delete(
    `/audience-builder/api/decision-maker/delete-donation-data/`,
    {
      params: {
        decision_maker_id: decisionMakerId,
        candidate_id: candidateId,
        upload_id: uploadId,
      },
    }
  );

  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createDonorsSegment = async (
  dm_id,
  desired_size,
  projectId,
  targetId,
  states = [],
  isDCMetro
) => {
  const response = await http.post(
    `/audience-builder/api/donor/add-donor-segment/`,
    {
      dm_id: dm_id,
      desired_size: desired_size,
      project_id: projectId,
      target: targetId,
      states: states,
      is_dc_metro: isDCMetro,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getCandidatesFromDonor = async (firstName, lastName) => {
  const response = await http.post(
    "/audience-builder/api/donor/get-candidates/",
    {
      first_name: firstName,
      last_name: lastName,
    }
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getExistingCandidacies = async (decisionMakerId) => {
  const response = await http.post(
    "/audience-builder/api/donor/get-linked-candidates/",
    {
      decision_maker_id: decisionMakerId,
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteExistingCandidacyLink = async (
  decisionMakerId,
  candidateId
) => {
  const response = await http.delete(
    `/audience-builder/api/decision-maker/candidate-link/`,
    {
      params: { decision_maker_id: decisionMakerId, candidate_id: candidateId },
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const addCandidacyLinks = async (
  linkCandidacy,
  user,
  decisionMakerId
) => {
  const candidates = [];
  linkCandidacy.forEach((item) => {
    candidates.push({
      candidate_id: item.id,
      source: item.source,
    });
  });
  const response = await http.post(
    "/audience-builder/api/donor/link-candidates/",
    {
      candidates: candidates,
      user: user,
      dm_id: decisionMakerId,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getAccounts = async () => {
  const response = await http.get(`/audience-builder/api/account/`);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getAccount = async (accountId) => {
  const response = await http.get(
    `/audience-builder/api/account/${accountId}/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getAccountsByDealStageType = async (dealStageType) => {
  const response = await http.get(
    `/audience-builder/api/account/get-all-accounts/`,
    {
      params: {
        dealstage: dealStageType,
      },
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error("Accounts cant be retrieved");
  }
};

export const patchClientStatus = async (hubspot_company_id, status) => {
  const response = await http.patch(`/audience-builder/api/account/status/`, {
    hubspot_company_id: hubspot_company_id,
    status: status,
  });
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error("Client Status cant be updated");
  }
};

export const getClientDeals = async (hubspot_company_id) => {
  const response = await http.get(`/audience-builder/api/account/deals/`, {
    params: {
      hubspot_company_id: hubspot_company_id,
    },
  });
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error("Client Deals cant be retrieved");
  }
};

export const getUsage = async (selectedDate) => {
  const response = await http.get(`/unit-tracking/target-units/usage/`, {
    params: {
      selected_month: selectedDate.month() + 1,
      selected_year: selectedDate.year(),
    },
  });
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const postReport = async (selectedDate) => {
  const response = await http.post(`/unit-tracking/target-units/report/`, {
    selected_month: selectedDate.month() + 1,
    selected_year: selectedDate.year(),
  });
  if (response.status === 200) {
    return fileDownload(
      response.data,
      `${selectedDate.year()}_${selectedDate.month() + 1}.csv`,
      "text/csv"
    );
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const deactivateTargetProjectLinks = async (
  effectiveDate,
  targetProjectLinkIds
) => {
  const response = await http.post(
    `/unit-tracking/target-actions/deactivate-dms/`,
    {
      effective_date: effectiveDate,
      tpl_ids: targetProjectLinkIds,
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const reactivateTargetProjectLinks = async (targetProjectLinkIds) => {
  const response = await http.post(
    `/unit-tracking/target-actions/reactivate-dms/`,
    {
      tpl_ids: targetProjectLinkIds,
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const patchUsageStartDates = async (usageLogIds, effectiveDate) => {
  const response = await http.patch(
    `/unit-tracking/target-units/usage-start/`,
    {
      usage_log_ids: usageLogIds,
      effective_date: effectiveDate,
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getBalance = async (accountId) => {
  const response = await http.get(
    `/unit-tracking/account-units/${accountId}/balance-v2/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDmSummary = async (accountId) => {
  const response = await http.get(
    `/audience-builder/api/account/${accountId}/dm_summary/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDecisionMakerReport = async (hubspotCompanyId) => {
  const response = await http.get(
    `/audience-builder/api/account/${hubspotCompanyId}/dm-details-report/`
  );
  if (response.status === 200) {
    return fileDownload(
      response.data,
      `${hubspotCompanyId}_decision_maker_report.csv`,
      "text/csv"
    );
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getConsumption = async (
  accountId,
  selectedStartDate,
  selectedEndDate
) => {
  const response = await http.get(
    `/unit-tracking/account-units/${accountId}/consumption/`,
    {
      params: {
        selected_start_month: selectedStartDate.month() + 1,
        selected_start_year: selectedStartDate.year(),
        selected_end_month: selectedEndDate.month() + 1,
        selected_end_year: selectedEndDate.year(),
      },
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDestination = async (hubspotCompanyId) => {
  const response = await http.get(`audience-builder/api/destination/`, {
    params: {
      client__hubspot_company_id: hubspotCompanyId,
    },
  });
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const postDestination = async (
  hubspotCompanyId,
  selectedDestinationName,
  selectedAdvertisingAccountId
) => {
  const response = await http.post(`/audience-builder/api/destination/`, {
    client__hubspot_company_id: hubspotCompanyId,
    name: selectedDestinationName,
    advertising_account_id: selectedAdvertisingAccountId,
  });
  if (response.status === 201) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteDestination = async (destinationId) => {
  const response = await http.delete(
    `/audience-builder/api/destination/${destinationId}/`,
    {}
  );
  if (response.status === 204) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getAudienceSegmentDestination = async (
  hubspotCompanyId,
  projectId
) => {
  const response = await http.get(
    `/audience-builder/api/account/${hubspotCompanyId}/project/${projectId}/dsp-audience-files/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const postDspAudienceDestination = async (
  dspAudienceId,
  destinationId
) => {
  const response = await http.post(
    `audience-builder/api/dsp-audience-destination/`,
    {
      dsp_audience: dspAudienceId,
      destination: destinationId,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export async function deleteDspAudienceDestination(dspAudienceDestinationId) {
  const response = await http.delete(
    `audience-builder/api/dsp-audience-destination/${dspAudienceDestinationId}/`
  );
  if (response.status === 204) {
    return response.data;
  }
  console.error(response.data);
  throw new Error(JSON.stringify(response.data));
}

export const getProjectDecisionMakers = async (hubspotCompanyId, projectId) => {
  const response = await http.get(
    `/audience-builder/api/account/${hubspotCompanyId}/project/${projectId}/decision-makers/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getAccountReport = async (hubspotCompanyId) => {
  const response = await http.get(
    `/audience-builder/api/account/${hubspotCompanyId}/report/`,
    {}
  );
  if (response.status === 200) {
    return fileDownload(
      response.data,
      `${hubspotCompanyId}_account_report.csv`,
      "text/csv"
    );
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getProjectReport = async (hubspotCompanyId, projectId) => {
  const response = await http.get(
    `/audience-builder/api/account/${hubspotCompanyId}/project/${projectId}/report/`,
    {}
  );
  if (response.status === 200) {
    return fileDownload(
      response.data,
      `${hubspotCompanyId}_project_${projectId}_report.csv`,
      "text/csv"
    );
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getAccountProjects = async (hubspotCompanyId) => {
  const response = await http.get(
    `/audience-builder/api/account/${hubspotCompanyId}/project/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getAccountCampaigns = async (hubspotCompanyId) => {
  const response = await http.get(`/campaign-manager/campaign/`, {
    params: { billing_client__hubspot_company_id: hubspotCompanyId },
  });
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getCampaign = async (campaignid) => {
  const response = await http.get(`/campaign-manager/campaign/${campaignid}/`);
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createCampaign = async (
  name,
  solution,
  useCase,
  media,
  endClient,
  billingClient
) => {
  const response = await http.post(`/campaign-manager/campaign/`, {
    name: name,
    solution: solution,
    use_case: useCase,
    media: media,
    end_client: endClient,
    billing_client: billingClient,
  });
  if (response.status === 201) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateCampaign = async (
  campaignId,
  name,
  solution,
  useCase,
  media,
  endClient,
  billingClient
) => {
  const response = await http.patch(
    `/campaign-manager/campaign/${campaignId}/`,
    {
      name: name,
      solution: solution,
      use_case: useCase,
      media: media,
      end_client: endClient,
      billing_client: billingClient,
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const isCampaignExported = async (campaignId) => {
  const response = await http.get(`/campaign-manager/campaign/${campaignId}/`);
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const editHVC = async (
  dmId,
  high_value_connection_relationship_id,
  connection_type,
  is_current,
  company,
  web_url,
  title
) => {
  const response = await http.put(
    `dms/decision-maker/${dmId}/high-value-connection-relationship/${high_value_connection_relationship_id}/`,
    {
      id: high_value_connection_relationship_id,
      connection_type: connection_type,
      is_current: is_current,
      company: company,
      web_url: web_url,
      title: title,
    }
  );
  if (response.status === 200) {
    return response;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const attachNewHVC = async (
  dmId,
  hvc_decision_maker_id,
  connection_type,
  is_current,
  company,
  web_url,
  title,
  created_by
) => {
  const response = await http.post(
    `dms/decision-maker/${dmId}/high-value-connection-relationship/${hvc_decision_maker_id}/`,
    {
      decision_maker_id: dmId,
      hvc_decision_maker_id: hvc_decision_maker_id,
      connection_type: connection_type,
      is_current: is_current,
      company: company,
      web_url: web_url,
      title: title,
      created_by: created_by,
    }
  );
  if (response.status === 201) {
    return response;
  } else if (response.status === 208) {
    return response;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getHVCs = async (dmId) => {
  const response = await http.post(
    `dms/decision-maker/high-value-connection-relationships/`,
    { dm_ids: [dmId] }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const removeHVC = async (
  dmId,
  high_value_connection_relationship_id
) => {
  const response = await http.delete(
    `dms/decision-maker/${dmId}/high-value-connection-relationship/${high_value_connection_relationship_id}/`,
    {}
  );
  if (response.status === 204) {
    return response;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const floodTargetProjectLink = async (targetProjectLinkId) => {
  const response = await http.post(`/unit-tracking/target-actions/flood/`, {
    target_project_link_id: targetProjectLinkId,
  });
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getLegislatorOrCandidatesFromStaffer = async (
  firstName,
  lastName
) => {
  const response = await http.post(
    "/staffer/search-candidates-and-legislators/",
    {
      first_name: firstName,
      last_name: lastName,
    }
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getStaffersForGrid = async (decision_maker_id = null) => {
  const response = await http.post(
    "/audience-builder/api/staffer/get-legislator-staffers-for-grid/",
    {
      decision_maker_id,
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const addPidToStaffer = async (source_id, p_id) => {
  const response = await http.post("/staffer/staffers-associations/", {
    source_id,
    p_id,
  });
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const removePidForStaffer = async (source_id) => {
  const response = await http.delete(
    `/staffer/staffers-associations/${source_id}/`
  );
  if (response.status === 204) {
    return true;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateStafferPid = async (source_id, p_id) => {
  const response = await http.put("/staffer/staffers-associations/", {
    source_id,
    p_id,
  });
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const addLegislatorsOrCandidatesLinks = async (
  legislatorOrCandidate,
  user,
  decisionMakerId
) => {
  const response = await http.post(
    `/dms/decision-maker/${decisionMakerId}/candidate-or-legislator/`,
    {
      decision_maker_id: decisionMakerId,
      candidate_or_legislator_id: legislatorOrCandidate.id,
      link_type: legislatorOrCandidate.type,
      created_by: user,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getLinkedLegislatorsOrCandidates = async (decisionMakerId) => {
  const response = await http.post(
    "/audience-builder/api/staffer/get-linked-candidates-legislators/",
    {
      decision_maker_id: decisionMakerId,
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteExistingLegislatorsOrCandidateLink = async (
  decisionMakerId,
  legislatorOrCandidateId,
  linkType
) => {
  const response = await http.post(
    `/dms/decision-maker/${decisionMakerId}/delete-candidate-or-legislator/`,
    {
      decision_maker_id: decisionMakerId,
      candidate_or_legislator_id: legislatorOrCandidateId,
      link_type: linkType,
    }
  );
  if (response.status === 204) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const createStafferSegment = async (
  dm_id,
  desired_size,
  projectId,
  targetId
) => {
  const response = await http.post(
    `/audience-builder/api/staffer/create-staffers-segment/`,
    {
      dm_id: dm_id,
      desired_size: desired_size,
      project_id: projectId,
      target: targetId,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const createHVCSegment = async (dm_id, projectId, targetId) => {
  const response = await http.post(
    `/audience-builder/api/hvc/add-hvc-from-relationships/`,
    {
      dm_id: dm_id,
      desired_size: -1,
      project_id: projectId,
      target: targetId,
    }
  );
  if (response.status === 201) {
    if (response.data.length === 0) {
      return alert("The target does not have High Value Connections");
    } else {
      return response.data;
    }
  } else if (response.status === 204) {
    return alert("Project HVC not configured");
  } else {
    throw new Error(JSON.stringify(response.data.error));
  }
};

export const createSecondDegreeSegment = async (
  projectId,
  targetId,
  secondaryDegreeRequest
) => {
  const response = await http.post(
    `/audience-builder/api/second-degrees/create-second-degrees/`,
    {
      project_id: projectId,
      target_id: targetId,
      second_degree_segment_requests: secondaryDegreeRequest,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getAvailableListsPerAccount = async (account_id) => {
  const response = await http.get(
    `/audience-builder/api/inclusion-exclusion-list/`,
    {
      params: {
        client: account_id,
      },
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getAvailableListsPerProject = async (project_id) => {
  const response = await http.get(
    `/audience-builder/api/project-inclusion-exclusion-list-relationship/`,
    {
      params: {
        project: project_id,
      },
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const attachListToProject = async (id, project_id) => {
  const response = await http.post(
    `/audience-builder/api/project-inclusion-exclusion-list-relationship/`,
    {
      list: id,
      project: project_id,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const removeListFromProject = async (id) => {
  const response = await http.delete(
    `/audience-builder/api/project-inclusion-exclusion-list-relationship/${id}/`
  );
  if (response.status === 204) {
    return true;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const createInExList = async (account_id, list_name, list_type) => {
  const response = await http.post(
    `/audience-builder/api/inclusion-exclusion-list/`,
    {
      client: account_id,
      list_name: list_name,
      list_type: list_type,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteInExList = async (list_id) => {
  const response = await http.delete(
    `/audience-builder/api/inclusion-exclusion-list/${list_id}/`
  );
  if (response.status === 204) {
    return true;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateInExList = async (list_id, list_name) => {
  const response = await http.patch(
    `/audience-builder/api/inclusion-exclusion-list/${list_id}/`,
    { list_name: list_name }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getInExListByListId = async (list_id) => {
  const response = await http.get(
    `/audience-builder/api/inclusion-exclusion-member/`,
    {
      params: {
        list_id: list_id,
      },
    }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const createInExListMemberByPII = async (list_id, values) => {
  const response = await http.post(
    `/audience-builder/api/inclusion-exclusion-member/`,
    {
      list: list_id,
      ...values,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const createInExListMemberByPID = async (list_id, p_id) => {
  const response = await http.post(
    `/audience-builder/api/inclusion-exclusion-member/`,
    {
      list: list_id,
      p_id: p_id,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getExclusionCompanies = async (listId) => {
  const response = await http.get(
    `/audience-builder/api/exclusion-company-group/?list=${listId}`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const createExCompanyListMember = async (list_id, company_id) => {
  const response = await http.post(
    `/audience-builder/api/exclusion-company-group/`,
    {
      list: list_id,
      company_canonical_id: company_id,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteInExListMember = async (member_id) => {
  const response = await http.delete(
    `/audience-builder/api/inclusion-exclusion-member/${member_id}/`
  );
  if (response.status === 204) {
    return true;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteExCompanyListMember = async (company_id) => {
  const response = await http.delete(
    `/audience-builder/api/exclusion-company-group/${company_id}/`
  );
  if (response.status === 204) {
    return true;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateInExListMember = async (member_id, values) => {
  const response = await http.patch(
    `/audience-builder/api/inclusion-exclusion-member/${member_id}/`,
    values
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createQualityAssuranceTimestamp = async (
  decisionMakerId,
  userEmail
) => {
  const response = await http.post(
    `/dms/decision-maker/${decisionMakerId}/qa/`,
    {
      decision_maker_id: decisionMakerId,
      updated_by: userEmail,
      event_type: "qa",
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createComprehensiveReviewTimestamp = async (
  decisionMakerId,
  userEmail
) => {
  const response = await http.post(
    `/dms/decision-maker/${decisionMakerId}/review/`,
    {
      decision_maker_id: decisionMakerId,
      updated_by: userEmail,
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createResearchTimestamp = async (decisionMakerId, userEmail) => {
  const response = await http.post(
    `/dms/decision-maker/${decisionMakerId}/research/`,
    {
      decision_maker_id: decisionMakerId,
      updated_by: userEmail,
      event_type: "research",
    }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDecisionMakerList = async (projectId) => {
  const response = await http.get(
    "/audience-builder/api/decision-maker-grid/",
    { params: { projectId } }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDecisionMakerByPIDs = async (personIDs) => {
  const response = await http.post(`dms/get-decision-makers-by-pid/`, {
    p_ids: personIDs,
  });
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDecisionMakerByExactNames = async (names) => {
  const response = await http.post(
    `dms/get-decision-makers-by-exact-names/`,
    names
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createStafferMatchAttemptLog = async (
  source_id,
  updated_by,
  event_type = "match_attempt"
) => {
  const response = await http.post(`/staffer/create-match-attempt-log/`, {
    source_id,
    updated_by,
    event_type,
  });
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getCompanyGroups = async (searchString) => {
  const response = await http.get(`eas/groups/find-groups/${searchString}/`);

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteCompanyGroup = async (group_id) => {
  const response = await http.delete(`eas/groups/${group_id}/`);
  if (response.status === 204) {
    return true;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateCompanyGroup = async (group_id, group_name) => {
  const response = await http.patch(`eas/groups/${group_id}/`, {
    name: group_name,
  });
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const createCompanyGroup = async (group_name) => {
  const response = await http.post(`eas/groups/`, { name: group_name });
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data.message));
  }
};

export const getAllCompanyGroups = async () => {
  const response = await http.get(`eas/groups/get-grid/`);

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getCompanyGroupInfo = async (group_id) => {
  const response = await http.get(`eas/groups/${group_id}/get-grid/`);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateCompanyGroupQualityAssuranceTimestamp = async (group_id) => {
  const response = await http.patch(`eas/groups/update-recent-qa/${group_id}/`);

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getGroupCompaniesByGroupId = async (group_id) => {
  const response = await http.get(`eas/groups/get-grid-by-id/${group_id}/`);

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateCanonicalCompany = async (group_id, company_id) => {
  const response = await http.patch(`eas/company-groups/`, {
    company_group_id: group_id,
    company_id,
  });

  if (response.status === 200) {
    return true;
  } else {
    throw new Error(JSON.stringify(response.status));
  }
};

export const deleteCompanyFromGroup = async (id) => {
  const response = await http.delete(`eas/company-groups/${id}/`);
  if (response.status === 204) {
    return true;
  } else {
    throw new Error(JSON.stringify(response.status));
  }
};

export const getGroupCompaniesBySearchString = async (searchString) => {
  const response = await http.get(
    `eas/company-groups/get-grid-by-name/${searchString}/`
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const bulkAddCompaniesToGroup = async (listOfCompanies) => {
  const response = await http.post("eas/company-groups/", listOfCompanies);
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.status));
  }
};

export const buildNetworks = async (dm_ids) => {
  const response = await http.post("/ns/build-network/", {
    dm_ids,
    is_test: false,
    event_type: "ns-api-build-network",
  });
  if (response.status === 200) {
    return;
  } else {
    throw new Error(JSON.stringify(response.status));
  }
};

export const createAutomatedNetworkBlocs = async (projectId, config_list) => {
  const response = await http.post(
    `/audience-builder/api/project/${projectId}/target-project-link/create-automated-network-blocs-v2/`,
    {
      config_list,
    }
  );

  if (response.status === 201) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateDonorMetaData = async (
  upload_meta_id,
  description,
  years
) => {
  const response = await http.post(`/donor/update-upload-meta/`, {
    upload_meta_id,
    description,
    years,
  });

  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDecisionMakerNotes = async (decisionMakerId) => {
  const response = await http.get(
    `dms/decision-maker/${decisionMakerId}/note/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const createDecisionMakerNote = async (decisionMakerId, user, note) => {
  const response = await http.post(
    `dms/decision-maker/${decisionMakerId}/note/`,
    { decision_maker_id: decisionMakerId, created_by: user, note: note }
  );
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data.message));
  }
};

export const updateDecisionMakerNote = async (id, decisionMakerId, note) => {
  const response = await http.put(
    `dms/decision-maker/${decisionMakerId}/note/${id}/`,
    { decision_maker_id: decisionMakerId, note: note }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const deleteDecisionMakerNote = async (decisionMakerId, id) => {
  const response = await http.delete(
    `dms/decision-maker/${decisionMakerId}/note/${id}/`
  );
  if (response.status === 204) {
    return true;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDMNetworkBuildingStatus = async (dm_ids) => {
  const response = await http.post(`ns/get-network-building-status/`, dm_ids);
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDecisionMakersForResearchRequests = async () => {
  const response = await http.post("/dms/get-dm-for-audience_builders/");
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const submitResearchRequests = async (
  dm_ids,
  due_date,
  requested_by
) => {
  const response = await http.post("/dms/research-requests/", {
    dm_ids,
    due_date,
    requested_by,
  });
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getResearchRequests = async () => {
  const response = await http.get("/dms/research-requests/");
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const updateResearchRequestAction = async (
  decisionMakerId,
  userEmail
) => {
  const response = await http.put(`/dms/research-requests/action-update/`, {
    decision_maker_id: decisionMakerId,
    update_user: userEmail,
  });
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export const createDecisionMakerResearchCheckoutLog = async (
  decision_maker_id,
  user
) => {
  const response = await http.post(`/dms/decision-maker-research-checkout/`, {
    decision_maker_id: decision_maker_id,
    updated_by: user,
  });
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const createDecisionMakerResearchReleaseLog = async (
  decision_maker_id,
  user
) => {
  const response = await http.post(`/dms/decision-maker-research-release/`, {
    decision_maker_id: decision_maker_id,
    updated_by: user,
  });
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const createDecisionMakerQACheckoutLog = async (
  decision_maker_id,
  user
) => {
  const response = await http.post(`/dms/decision-maker-qa-checkout/`, {
    decision_maker_id: decision_maker_id,
    updated_by: user,
  });
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const createDecisionMakerQAReleaseLog = async (
  decision_maker_id,
  user
) => {
  const response = await http.post(`/dms/decision-maker-qa-release/`, {
    decision_maker_id: decision_maker_id,
    updated_by: user,
  });
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getActiveResearchRequests = async (decisionMakerId) => {
  const response = await http.get(
    `/dms/get-active-research-requests/${decisionMakerId}/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getLastCheckoutLog = async (decisionMakerId) => {
  const response = await http.get(
    `/dms/get-last-checkout-log/${decisionMakerId}/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDecisionMakerQACheckoutLogStatus = async (decisionMakerId) => {
  const response = await http.get(
    `/dms/get-checkout-status/${decisionMakerId}/qa/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};

export const getDecisionMakerResearchCheckoutLogStatus = async (
  decisionMakerId
) => {
  const response = await http.get(
    `/dms/get-checkout-status/${decisionMakerId}/research/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
};
