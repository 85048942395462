import { Button, DatePicker, Modal, Popconfirm, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  showDangerMessage,
  showSuccessMessage,
  sortColumnWithNulls,
} from "../../../utils";
import {
  buildNetworks,
  getDecisionMakersForResearchRequests,
  getEASCompanies,
  submitResearchRequests,
} from "../../../APIClient";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getColumnSearchProps } from "../../../util/columnSearchPropFactory";
import moment from "moment";
import { useSelector } from "react-redux";
import { formatDecisionMakerQAStatus } from "../QualityAssurance/DecisionMakerStatus";
import { CreateDecisionMakerDrawer } from "../DecisionMakerList/CreateDecisionMakerDrawer";

const RequestsAdminGrid = () => {
  const dateFormat = "YYYY-MM-DD h:mm A";
  const [decisionMakers, setDecisionMakers] = useState([]);
  const [dueDate, setDueDate] = useState(
    moment().add(1, "day").hour(18).minute(0).second(0)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDecisionMakers, setSelectedDecisionMakers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [creationDrawerIsVisible, setCreationDrawerIsVisible] = useState(false);

  const user = useSelector((state) => state.user);

  const getDecisionMakerGrid = async () => {
    try {
      setIsLoading(true);
      const data = await getDecisionMakersForResearchRequests();
      const organizationData = await getEASCompanies([
        ...new Set(
          data.map((item) => item.company_id).filter((company_id) => company_id)
        ),
      ]);

      setDecisionMakers(
        data.map((x) => {
          return {
            key: x.dm_id,
            ...x,
            ...organizationData.find(
              (organizationItem) => organizationItem.company_id === x.company_id
            ),
          };
        })
      );
      setIsLoading(false);
    } catch (error) {
      showDangerMessage("An error occurred while fetching decision makers");
    }
  };

  useEffect(() => {
    getDecisionMakerGrid();
  }, []);

  const columns = [
    {
      title: "DM id",
      dataIndex: "dm_id",
      sorter: (a, b) => a.dm_id - b.dm_id,
      render: (_, record) => (
        <Link
          to={`/decision-makers/${record.dm_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {record.dm_id}
        </Link>
      ),
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      sorter: (a, b) => a.first_name?.localeCompare(b.first_name),
      ...getColumnSearchProps("first_name", "First Name"),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      sorter: (a, b) => a.last_name?.localeCompare(b.last_name),
      ...getColumnSearchProps("last_name", "Last Name"),
    },
    {
      title: "DM Type",
      dataIndex: "decision_maker_type",
      sorter: (a, b) =>
        a.decision_maker_type?.localeCompare(b.decision_maker_type),
      ...getColumnSearchProps("decision_maker_type", "Decision Maker Type"),
    },
    {
      title: "Organization",
      dataIndex: "company_name",
      sorter: (a, b) =>
        sortColumnWithNulls(a.decision_maker_type, b.decision_maker_type),
      ...getColumnSearchProps("company_name", "Organization"),
    },
    {
      title: "Title",
      dataIndex: "dm_title",
      sorter: (a, b) => sortColumnWithNulls(a.dm_title, b.dm_title),
      ...getColumnSearchProps("dm_title", "Title"),
    },
    {
      title: "QA Status",
      dataIndex: "qa_status",
      onFilter: (value, record) => record.qa_status === value,
      filters: [
        {
          text: "No QA",
          value: "no_qa",
        },
        {
          text: "Edited",
          value: "edited",
        },
        {
          text: "Expired",
          value: "expired",
        },
      ],
      render: (value) => formatDecisionMakerQAStatus(value, false, false),
      sorter: (a, b) => sortColumnWithNulls(a.qa_status, b.qa_status),
    },
  ];

  const onFinish = async () => {
    try {
      await submitResearchRequests(selectedDecisionMakers, dueDate, user.email);
      showSuccessMessage(
        `(${selectedDecisionMakers.length}) Decision Maker(s) were requested successfully`
      );
      setSelectedDecisionMakers([]);
      setDueDate(moment().add(1, "day").hour(18).minute(0).second(0));
    } catch (error) {
      showDangerMessage(
        "Ann error occured while requesting decision-makers for research/qa"
      );
    } finally {
      setIsModalOpen(false);
    }
  };

  const buildDmNetworks = async () => {
    try {
      await buildNetworks(selectedDecisionMakers);
      showSuccessMessage(
        `Build network request for (${selectedDecisionMakers.length}) Decision Maker(s) was requested successfully!`
      );
      setSelectedDecisionMakers([]);
    } catch (error) {}
  };

  return (
    <>
      <CreateDecisionMakerDrawer
        isVisible={creationDrawerIsVisible}
        setIsVisible={setCreationDrawerIsVisible}
        loadDecisionMakers={getDecisionMakerGrid}
      />
      <Table
        dataSource={decisionMakers}
        rowKey={(record) => record.dm_id}
        rowSelection={{
          selectedRowKeys: selectedDecisionMakers,
          onChange: (selectedRowKeys, _) => {
            setSelectedDecisionMakers(selectedRowKeys);
          },
        }}
        loading={isLoading}
        columns={columns}
        pagination={{
          position: ["topRight"],
          showTotal: () => {
            return (
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  marginRight: 20,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div style={{ display: "flex" }}>
                  <label style={{ fontSize: 18, fontWeight: 500 }}>
                    Due Date:
                  </label>
                  <DatePicker
                    showTime
                    use12Hours
                    style={{ marginLeft: 6 }}
                    id="date-picker"
                    defaultValue={dueDate}
                    allowClear={false}
                    onChange={(value) => {
                      setDueDate(value);
                    }}
                    disabledDate={(current) =>
                      current.isBefore(moment().subtract(1, "day"))
                    }
                    format={dateFormat}
                  />
                </div>
                <Button
                  type="primary"
                  style={{ margin: "0 20px", padding: "0 25px" }}
                  disabled={selectedDecisionMakers.length < 1}
                  onClick={() => setIsModalOpen(true)}
                >
                  Submit For Research/QA
                </Button>
                <Modal
                  visible={isModalOpen}
                  closable={true}
                  okText="Confirm"
                  onOk={onFinish}
                  onCancel={() => setIsModalOpen(false)}
                >
                  <h4
                    style={{ paddingRight: 10 }}
                  >{`Are you sure you want to request the following ${
                    selectedDecisionMakers.length
                  } decision maker(s) with due date ${dueDate?.format(
                    dateFormat
                  )}`}</h4>
                  <div style={{ marginTop: 20 }}>
                    {decisionMakers
                      .filter(({ dm_id }) =>
                        selectedDecisionMakers.includes(dm_id)
                      )
                      .sort((a, b) => a.first_name.localeCompare(b.first_name))
                      .map(
                        ({
                          dm_id,
                          first_name,
                          last_name,
                          decision_maker_type,
                        }) => (
                          <p
                            key={`decision-maker-${dm_id}`}
                          >{`${dm_id} ${first_name} ${last_name} ${decision_maker_type}`}</p>
                        )
                      )}
                  </div>
                </Modal>
                <Popconfirm
                  title={`Are you sure you want to build network(s) for (${selectedDecisionMakers.length}) decision-maker(s)`}
                  onConfirm={buildDmNetworks}
                  okText="Yes"
                  cancelText="No"
                  disabled={selectedDecisionMakers.length < 1}
                >
                  <Button disabled={selectedDecisionMakers.length < 1}>
                    Build Network(s)
                  </Button>
                </Popconfirm>
                <Button
                  style={{ marginLeft: 20 }}
                  onClick={() => setCreationDrawerIsVisible(true)}
                >
                  Create Decision Maker
                </Button>
              </div>
            );
          },
        }}
      />
    </>
  );
};

export default RequestsAdminGrid;
